<template>
  <div class="homeContent">
    <div class="leftBox" :style="leftRemindList.length>6?'padding-right:5px':''">
      <div class="leftTop">
        <div class="title">待办</div>
        <div class="date">{{clickDay}}{{isToday?'（今天）':''}}</div>
      </div>
      <div class="leftBody" v-loading='loading' 
      :style="leftRemindList.length>6?'overflow-y:scroll;padding-right:15px':''">
        <div class="itemBox" 
        v-for='(item,index) in leftRemindList' :key='item.todoId'>
          <div class='itemTopBox'>
            <div class="itemTop">
              <el-tooltip effect="light" 
              :content="item.todoName" placement="top-start">
                <div class="title" @click='clickTitle(index)'>
                  {{item.todoName}}
                </div>
              </el-tooltip>
              <div class="checkBox" @click="checkBox(index)">
                <div v-if='item.isFinish' class="iconfont iconduihao"></div>
              </div>
            </div>
            <div class="time">
              <div class="timeText">{{item.beginDate.substring(11,16)}}</div>
              <div class="iconList"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="leftFooter">
        <div class="addBusiness">
          <el-popover
            placement="top-start"
            :width="220"
            v-model:visible="addBusinessBtn"
            @after-leave='addBusinessBtnLeave'
          >
            <template #reference>
              <div class="iconfont iconjiahao" @click='quickCreateBtn'></div>
            </template>
            <div class="addBox">
              <el-input
                size='mini'
                class='addInput'
                placeholder="请输入待办事项"
                v-model="addBusinessInput"
                clearable>
              </el-input>
              <div class="timerBox">
                <div class="timerPickerBox">
                  <el-date-picker
                    size='mini'
                    v-model="timerPicker"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </div>
                <div class="submitBtn">
                  <el-button size='mini' @click='addBusinessBtn=false'>取消</el-button>
                  <el-button :loading="addBusinessLoading" type='primary' size='mini' @click='addBusiness'>保存</el-button>
                </div>
              </div>
            </div>
          </el-popover>
          <div class="addText" v-if='getIsFinishNum'>
            <el-checkbox v-model="checkedIsShow">
              {{getIsFinishNum}}项<span>已完成</span>
            </el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="centerBox">
      <div class='calendarBox'>
        <calendar @changeDate='changeDate' ref='calendar'></calendar>
      </div>
    </div>
    <div class="rightBox">
      <div class="title">我的菜单</div>
      <div class="menuLis">
        <div class="menuItem" v-for='item in myMenuList' :key='item.id' @click='enter(item.id)'>
          <div :class="['iconfont',item.icon]"></div>
          <div class="menuText">{{item.name}}</div>
        </div>
      </div>
      <div class="problem">
        <div class="iconfont iconwentidan"></div>
        <div class="problemText">问题反馈</div>
      </div>
      <div class="video">
        <div class="videoText">视频教程</div>
        <div class="iconfont iconshipin"></div>
      </div>
    </div>
    <!-- 待办详情 -->
    <myDialog 
    :isShow='toDoListIsShowChange'
    :closeDialog='toDoListCloseDialogChange'
    title='编辑待办'
    @dialogAfterLeave='toDoListDialogAfterLeaveChange'>
      <el-form label-position="top"
      v-loading="loadingChange"
      :model="toDoFormChange" 
      :rules="toDoFormRules" ref="ToDoFormChange" label-width="100px" id="toDoForm">
      <div class='contentDialog'>  
        <el-form-item label="待办事项" prop="title">
          <el-input :disabled='toDoFormChange.isFinish==1' v-model="toDoFormChange.title" placeholder="请输入待办事项名称"></el-input>
        </el-form-item>
        <el-form-item label="待办详情">
          <el-input :disabled='toDoFormChange.isFinish==1' type='textarea' :rows="4" 
          v-model="toDoFormChange.details" placeholder="请输入待办详情"></el-input>
        </el-form-item>
        <div class="item">
          <el-form-item label="开始时间" prop='startTime'>
            <el-date-picker size='medium' :disabled='toDoFormChange.isFinish==1'
              v-model="toDoFormChange.startTime"
              type="datetime"
              placeholder="请输入开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker size='medium' :disabled='toDoFormChange.isFinish==1'
              v-model="toDoFormChange.endTime"
              type="datetime"
              placeholder="请输入结束时间">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="addBox">
          <div class="addTitle">
            <div class="title">提醒</div>
            <el-button :disabled='toDoFormChange.isFinish==1' type="primary" plain icon="el-icon-plus" size='mini' 
            @click='addRemindChange'>添加</el-button>
          </div>
          <div class="list">
              <div class="listItem" 
              v-for='(item,index) in toDoFormChange.remind' :key='item.key'>
                <el-form-item style='margin-bottom:0' 
                :prop="'remind.' + index + '.value'"
                :rules="{required: true, message: '提醒时间不能为空', trigger: ['blur', 'change']}">
                  <el-select :disabled='toDoFormChange.isFinish==1'
                  v-model="toDoFormChange.remind[index]['value']" 
                  size='medium' style='width:220px'>
                    <el-option
                      v-for="item in selectVal"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item style='margin-bottom:0' 
                v-if="toDoFormChange.remind[index]['value']==1" 
                :prop="'remind.' + index + '.selfValue'"
                :rules="{required: true, message: '自定义时间不能为空', trigger: ['blur', 'change']}">
                  <el-date-picker
                    :disabled='toDoFormChange.isFinish==1'
                    size='medium'
                    v-model="toDoFormChange.remind[index]['selfValue']"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
                <i class='el-icon-delete' 
                :style='(index==0?"visibility:hidden":"")||toDoFormChange.isFinish==1?"visibility:hidden":""' 
                @click='deleteRemindChange(index)'></i>
              </div>
          </div>
        </div>
        <!-- <el-form-item label="关联">
          <div class="relation">
            <el-select v-model="toDoForm.relation" 
            size='medium' style='width:25%'>
              <el-option
                v-for="item in relationSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div class="relationInput">
              <el-select v-model="toDoForm.relationContent" 
              size='medium' style='width:100%' :disabled='toDoForm.relation==0'>
                <el-option
                  v-for="item in relationSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item> -->
        </div>
        <el-form-item>
          <div class='btngorw'>
            <el-button v-if='toDoFormChange.isFinish==0' @click="toDoListDeletToDoFormChange">删除</el-button>
            <el-button v-if='toDoFormChange.isFinish==0' type="primary" plain @click="toDoListFinishedToDoFormChange">已完成</el-button>
            <el-button v-if='toDoFormChange.isFinish==0' type="primary" @click="toDoListSubmitToDoFormChange">保存</el-button>
            <el-button v-if='toDoFormChange.isFinish==1' type="primary" @click="toDoListRevokeToDoFormChange">撤销完成</el-button>
          </div>
        </el-form-item>
      </el-form>
    </myDialog>
    <!-- 新建待办 -->
    <myDialog 
    :isShow='toDoListIsShow'
    :closeDialog='toDoListCloseDialog'
    title='新建待办'
    @dialogAfterLeave='toDoListDialogAfterLeave'>
      <el-form label-position="top" 
      :model="toDoForm" 
      :rules="toDoFormRules" ref="ToDoForm" label-width="100px" id="toDoForm">
        <el-form-item label="待办事项" prop="title">
          <el-input v-model="toDoForm.title" placeholder="请输入待办事项名称"></el-input>
        </el-form-item>
        <el-form-item label="待办详情">
          <el-input type='textarea' :rows="4" 
          v-model="toDoForm.details" placeholder="请输入待办详情"></el-input>
        </el-form-item>
        <div class="item">
          <el-form-item label="开始时间" prop='startTime'>
            <el-date-picker size='medium'
              v-model="toDoForm.startTime"
              type="datetime"
              placeholder="请输入开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker size='medium'
              v-model="toDoForm.endTime"
              type="datetime"
              placeholder="请输入结束时间">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="addBox">
          <div class="addTitle">
            <div class="title">提醒</div>
            <el-button type="primary" plain icon="el-icon-plus" size='mini' @click='addRemind'>添加</el-button>
          </div>
          <div class="list">
              <div class="listItem" v-for='(item,index) in toDoForm.remind' :key='item.key'>
                <el-form-item style='margin-bottom:0' 
                :prop="'remind.' + index + '.value'">
                  <el-select v-model="toDoForm.remind[index]['value']" size='medium' style='width:220px'>
                    <el-option
                      v-for="item in selectVal"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item style='margin-bottom:0' 
                v-if="toDoForm.remind[index]['value']==1" 
                :prop="'remind.' + index + '.selfValue'"
                :rules="{required: true, message: '自定义时间不能为空', trigger: ['blur', 'change']}">
                  <el-date-picker
                    size='medium'
                    v-model="toDoForm.remind[index]['selfValue']"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
                <i class='el-icon-delete' :style='index==0?"visibility:hidden":""' @click='deleteRemind(index)'></i>
              </div>
            
          </div>
        </div>
        <!-- <el-form-item label="关联">
          <div class="relation">
            <el-select v-model="toDoForm.relation" 
            size='medium' style='width:25%'>
              <el-option
                v-for="item in relationSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div class="relationInput">
              <el-select v-model="toDoForm.relationContent" 
              size='medium' style='width:100%' :disabled='toDoForm.relation==0'>
                <el-option
                  v-for="item in relationSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item> -->
        <el-form-item >
          <div class='btngorw'>
            <el-button @click="toDoListCancelToDoForm">取消</el-button>
            <el-button type="primary" @click="toDoListSubmitToDoForm">立即创建</el-button>
          </div>
        </el-form-item>
      </el-form>
    </myDialog>
    <!-- 新建客户弹框 -->
    <myDialog title="填写客户基本信息" 
    :isShow="isShow4" 
    :closeDialog="handleCloseDialog4">
      <div class="custom_form">
        <el-form 
        :model="customForm" 
        :rules="rules4" 
        ref="customForm" label-width="100px"
          class="demo-ruleForm">
          <div class="form_box">
            <el-form-item label="客户名称" class="form_item_type" prop="customerType">
              <el-select v-model="customForm.customerType" size="medium">
                <el-option v-for="item in customerTypeArr" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerName" class="form_item_name">
              <el-input v-model="customForm.customerName" size="medium"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="客户标签">
            <el-select v-model="customForm.tagList" multiple style="width: 100%;" placeholder="请选择" size="medium">
              <el-option v-for="item in customerTagArr" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker v-model="customForm.birthday" type="date" style="width: 100%" placeholder="年/月/日"
              size="medium" @change="handleChangeTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="customForm.customerRemark" size="medium"></el-input>
          </el-form-item>
          <div class="btn_wrap">
            <el-button class="btn" type="primary" @click="save4">保存</el-button>
          </div>
        </el-form>
      </div>
    </myDialog>
    <!-- 新建案件 -->
    <myDialog 
    @dialogAfterLeave='afterLeaveCase'
    :title='oppTitle'
    :isShow='oppIsShowCase'
    :closeDialog='closeDialogCase'
    >
      <div>
        <div class="radioGroup" v-show="isShowRadioGroup">
          <el-radio-group v-model="caseRadio" @change='changeRadio'>
            <el-radio 
            v-for='item in caseType' :key='item.key' 
            :label="item.key">{{item.value}}</el-radio>
          </el-radio-group>
        </div>
        <div class="formBaseInfo" v-show="isShowFormBaseInfo">
          <div style='border-bottom:1px solid #eee'>
            <party ref='party'></party>
          </div>
          <div>
            <party ref='party2' title='对方当事人'></party>
          </div>
          <div class="lawyerBox">
            <div class="itemLawyer">
              <div class="titleItem">案件名称</div>
              <div class="inputItem">
                <el-input v-model="caseName" placeholder="请输入案件名称" size='small'></el-input>
              </div>
            </div>
            <div class="itemLawyer" style='align-items:flex-start;'>
              <div class="titleItem" style='padding-top: 13px;'>经办律师</div>
              <div class="inputItem">
                <lawyerParty ref='lawyerParty'></lawyerParty>
              </div>
            </div>
          </div>
          <div class="belongBox">
            <div class="caseBelong">
              <div class="text">案件归属</div>
              <div class="select">
                <el-select size='small' style="width: 100px;"
                v-model="beLongOptionsVal" placeholder="请选择">
                  <el-option
                    v-for="item in currentId"
                    :key="item.keyL"
                    :label="item.value"
                    :value="item.keyL">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="caseBelong">
              <div class="text">案源客户</div>
              <div class="select">
                <el-select size='small' style="width: 100px;"
                v-model="customOptionsVal" placeholder="请选择">
                  <el-option
                    v-for="item in customList"
                    :key="item.keyL"
                    :label="item.value"
                    :value="item.keyL">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="bottomBox">
            <el-button type='primary' size='small' @click='saveCreateCase'>完成创建</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 新建项目 -->
    <myDialog 
    @dialogAfterLeave='afterLeaveProject'
    :title='oppTitleProject'
    :isShow='oppIsShowProject'
    :closeDialog='closeDialogProject'
    >
      <div>
        <div class="radioGroup" v-show="isShowRadioGroupProject">
          <el-radio-group v-model="radioProject" @change='changeRadioProject'>
            <el-radio :label="1">专项服务</el-radio>
            <el-radio :label="2">顾问单位</el-radio>
            <el-radio :label="3">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="formBaseInfo" v-show="isShowFormBaseInfoProject">
          formBaseInfo
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent, toRefs, computed, ref,reactive, onMounted, vModelRadio} from 'vue';
import {useStore} from 'vuex';
import calendar from '@/components/calendar.vue';
import myDialog from '@/components/myDialog.vue';
import {quickCreate,today,create,update,detail,check,remove} from '@/api/index.js';
import {ElMessage} from 'element-plus';
import {transformDateTime} from '@/utils/index.js';
import {addCustomer} from '@/api/custom';
import lawyerParty from '@/components/lawyerParty.vue';
import party from '@/components/party.vue';
import {getListByDictType,saveCaseBasicInfo,customerSelect,getLanderUserSelect} from '@/api';

// 日期是否为当天
const useIsToday = (store)=>{
  let isToday = computed(()=>{
    return store.getters.isTody
  })
  return{
    isToday
  }
};
// 获取用户点击日历的日期
const useGetClickDay = (store)=>{
  let { homeCalendar } = toRefs(store.state);
  let clickDay = computed(()=>{
    return homeCalendar.value.substring(5);
  })
  return {
    clickDay,
    homeCalendar
  }
};
// 新建案件
const getCaseType = (store)=>{
  const caseType = ref([]);
  const customList = ref([]);
  const currentId = ref([]);
  const caseTypeData = async ()=>{
    return await store.dispatch('getDataDictionary',10003);
  };
  const getCustom = async ()=>{
    return await customerSelect()
  }
  // 当前登录人的租户id
  const getCurrentId = async ()=>{
    return await getLanderUserSelect();
  }
  onMounted(async ()=>{
    caseType.value = await caseTypeData();
    let res = await getCustom();
    if(res&&res.code==200){
      customList.value = res.data
    };
    let currentbeLoingsId = await getCurrentId();
    if(currentbeLoingsId&&currentbeLoingsId.code==200){
      currentId.value = currentbeLoingsId.data
    };
  })
  return {
    caseType,
    customList,
    currentId
  }
};

export default defineComponent({
  name:'HomeMain',
  setup(){
    const store = useStore();
    const {isToday} = useIsToday(store);
    const {clickDay,homeCalendar} = useGetClickDay(store);
    const {caseType,customList,currentId} = getCaseType(store);
    return {
      isToday,
      clickDay,
      homeCalendar,
      caseType,
      customList,
      currentId
    }
  },
  data(){
    return {
      checkedIsShow:false,
      specifyDay:'',
      leftRemindList:[],
      leftRemindAllList:[],
      loading:true,
      toDoForm:{
        title:'',
        details:'',
        startTime:'',
        endTime:'',
        remind:[{
          key:Date.now(),
          value:'',
          selfValue:''
        }],
        relation:'',
        relationContent:''
      },
      toDoFormRules:{
        title:{ required: true, message: '请输入待办事项名称', trigger: ['blur', 'change'] },
        startTime:{required: true, message: '请输入开始时间', trigger: ['blur', 'change']},
      },
      relationSelect:[],
      myMenuList:[
        {
          id:1,
          name:"新建案件",
          icon:"iconanjiantiaocha"
        },
        {
          id:2,
          name:"新建项目",
          icon:"iconxiangmu"
        },
        {
          id:3,
          name:"新建待办",
          icon:"icondaibanshezhi"
        },
        {
          id:4,
          name:"新建客户",
          icon:"iconkehuguanli"
        }
      ],
      toDoListIsShow:false,
      selectVal:[],
      addBusinessLoading:false,
      addBusinessBtn:false,
      addBusinessInput:'',
      timerPicker:'',
      // 编辑待办弹框
      toDoListIsShowChange:false,
      toDoFormChange:{
        title:'',
        details:'',
        startTime:'',
        endTime:'',
        remind:[],
        isFinish:'',
        relateType:'',
        todoId:'',
        relation:'',
        relationContent:''
      },
      loadingChange:false,
      rules4: {
        customerType: [
          {
            required: true,
            message: '请选择客户类型',
            trigger: 'blur'
          }
        ],
        customerName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }]
      },
      isShow4:false,
      customerTagArr: [],
      customerTypeArr: [],
      customForm: {
        customerName: '',
        customerType: 1,
        birthday: '',
        customerRemark: '',
        tagList: []
      },
      // 新建案件
      oppIsShowCase:false,
      isShowRadioGroup:false,
      caseRadio:'',
      isShowFormBaseInfo:false,
      caseName:'',
      beLongOptionsVal:'',
      options: [{
        value: 1,
        label: '个人'
      }],
      customOptionsVal:'',
      oppTitle:'请选择案件类型',
      // 新建项目
      oppTitleProject:'请选择项目类型',
      oppIsShowProject:false,
      isShowRadioGroupProject:false,
      radioProject:'',
      isShowFormBaseInfoProject:false,
    }
  },
  components:{
    calendar,
    myDialog,
    lawyerParty,
    party
  },
  created(){
    this.specifyDay = transformDateTime(new Date());
    this.getTodoList();
    this.getCustomerType();
    this.getCustomTag()
  },
  watch:{
    checkedIsShow(newV,oldV){
      if(!newV){
        // 显示待完成
        this.leftRemindList = this.leftRemindAllList.filter(item=>{
          return item.isFinish === 0
        });
      }else{
        // 显示全部
        this.leftRemindList = this.leftRemindAllList;
      }
    }
  },
  computed:{
    // 当日待办中已完成数
    getIsFinishNum:function(){
      if(this.leftRemindAllList.length){
        const filterArr = this.leftRemindAllList.filter(item=>{
          return item.isFinish === 1
        });
        return filterArr.length
      }else{
        return 0
      }
    },
  },
  methods:{
    // 新建案件
    afterLeaveCase(){
      // this.caseName = '';
      // this.beLongOptionsVal = '';
      // this.customOptionsVal = '';
      // this.$refs['party'].resetFields();
      // this.$refs['party2'].resetFields();
      // this.$refs['lawyerParty'].resetFields();
    },
    closeDialogCase(){
      this.caseName = '';
      this.beLongOptionsVal = '';
      this.customOptionsVal = '';
      this.$refs['party'].resetFields();
      this.$refs['party2'].resetFields();
      this.$refs['lawyerParty'].resetFields();
      this.oppIsShowCase = false;
      this.isShowRadioGroup = true;
      this.oppTitle = '请选择案件类型';
      this.isShowFormBaseInfo = false;
      this.caseRadio = '';
    },
    changeRadio(){
      this.isShowRadioGroup = false;
      this.oppTitle = '填写案件基本信息';
      this.isShowFormBaseInfo = true;
    },
    async saveCreateCase(){
      // 提交的数据字段
      // let formDada = {
      //   caseName:'',
      //   caseNo:'',
      //   caseType:'',
      //   customerId:'',
      //   entrustDate:'',
      //   factualReason:'',
      //   happenDate:'',
      //   oppositeLitigantList:[],
      //   ourLitigantList:[],
      //   relLawyerList:[],
      //   subject:'',
      //   subjectAmount:''
      // };

      // 校验各个组件必填项
      let checkValidate;
      let checkValidate2;
      let checkValidate3;
      this.$refs['party'].checkIsRequire((v)=>{
        checkValidate = v
      });
      this.$refs['party2'].checkIsRequire((v)=>{
        checkValidate2 = v
      });
      this.$refs['lawyerParty'].checkIsRequire((v)=>{
        checkValidate3 = v
      })
      if(checkValidate&&checkValidate2&&checkValidate3){
        let formDada = {
          caseName:'',
          caseType:'',
          oppositeLitigantList:[],
          ourLitigantList:[],
          relLawyerList:[],
          customerId:''
        };
        // 案件类型
        formDada.caseType = this.caseRadio;
        // 我方当事人
        // console.log(this.$refs['party'].ruleForm.formList)
        this.$refs['party'].ruleForm.formList.forEach(item=>{
          formDada.ourLitigantList.push({
            holderName:item.nameinput,
            holderType:item.type,
            partyType:item.identify,
            createCustomerFlag:item.isCreatedCustom?1:0
          })
        })
        // 对方当事人
        // console.log(this.$refs['party2'].ruleForm.formList)
        this.$refs['party2'].ruleForm.formList.forEach(item=>{
          formDada.oppositeLitigantList.push({
            holderName:item.nameinput,
            holderType:item.type,
            partyType:item.identify
          })
        })
        // 我方和对方当事人比对
        let conflictIdx;
        formDada.ourLitigantList.forEach((ourItem,idx)=>{
          formDada.oppositeLitigantList.forEach(item=>{
            if(item.holderName == ourItem.holderName){
              conflictIdx = {
                idx,
                ourItem
              }
            }
          })
        });
        if(conflictIdx&&conflictIdx.ourItem){
          ElMessage.warning({
            message: '我方和对方当事人不能一样',
            type: 'warning'
          });
          return
        };
        // 案件名称
        if(!this.caseName){
          let caseType = '';
          this.caseType.forEach(item=>{
            if(this.caseRadio==item.key){
              caseType = item.value
            }
          });
          this.caseName = `${formDada.ourLitigantList[0].holderName}和${formDada.oppositeLitigantList[0].holderName}的${caseType}案`;
        };
        formDada.caseName = this.caseName;
        // 经办律师
        // console.log(this.$refs['lawyerParty'].ruleForm.formList)
        this.$refs['lawyerParty'].ruleForm.formList.forEach(item=>{
          formDada.relLawyerList.push({
              lawyerName:item.nameinput,
              lawyerType:item.type,
              lawyerUserId:item.keyL?item.keyL:''
            })
        });
        // 案件归属
        // console.log(this.beLongOptionsVal)
        this.$store.commit('getCurrentbeLoingsId',this.beLongOptionsVal);
        // 案源客户
        formDada.customerId = this.customOptionsVal;
        // console.log('新建案件:',formDada)

        let res = await saveCaseBasicInfo(formDada);
        if(res&&res.code==200){
          ElMessage.success({
            message: '创建成功',
            type: 'success'
          });
          this.closeDialogCase();
        }
        
      }else{
        console.log(checkValidate,checkValidate2,checkValidate3)
        return
      }
      
    },
    // 新建项目
    closeDialogProject(){
      this.oppIsShowProject = false
    },
    afterLeaveProject(){},
    changeRadioProject(){
      this.isShowRadioGroupProject = false;
      this.oppTitle = '填写项目基本信息';
      this.isShowFormBaseInfoProject = true;
    },
    // 获取客户类型
   async getCustomerType(){
      let res = await getListByDictType({
        id: 10005
      })
      this.customerTypeArr = res.data
    },
    // 获取客户标签
    async getCustomTag(){
      let res = await getListByDictType({id: 10031})
      this.customerTagArr = res.data
    },
    formatTime(dataStr){
      let date = new Date(dataStr);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d
    },
    handleChangeTime(val){
      this.customForm.birthday = this.formatTime(val)
    },
    quickCreateBtn(){
      this.addBusinessBtn = true
    },
    addBusinessBtnLeave(){
      this.addBusinessInput = '';
      this.timerPicker = '';
      this.addBusinessLoading = false
    },
    // 左侧点击详情
    async clickTitle(idx){
      this.toDoListIsShowChange = true;
      this.loadingChange = true;
      let res = await detail({
        todoId:this.leftRemindList[idx].todoId
      });
      let selectVal = await this.$store.dispatch('getDataDictionary',10001);
      this.selectVal = selectVal;
      if(res&&res.code == 200){
        if(res.data.reminderList.length){
          let reminderList = res.data.reminderList;
          reminderList.forEach(item=>{
            this.toDoFormChange.remind.push({
              key:Date.now(),
              value:item.remindType,
              selfValue:item.remindTime
            })
          })
        }
        this.toDoFormChange.title = res.data.todoName;
        this.toDoFormChange.details = res.data.todoRemark?res.data.todoRemark:'';
        this.toDoFormChange.startTime = res.data.beginDate;
        this.toDoFormChange.endTime = res.data.endDate?res.data.endDate:'';
        this.toDoFormChange.isFinish = res.data.isFinish;
        this.toDoFormChange.relateType = 1;
        this.toDoFormChange.todoId = res.data.todoId;
      }else{
        this.toDoListIsShowChange = false;
      }
      this.loadingChange = false;
      console.log(res)
    },
    // 左侧快捷添加代办
    async addBusiness(){
      if(!this.addBusinessInput){
        ElMessage.warning({
          message: '待办事项不能为空',
          type: 'warning'
        });
        return
      };
      if(!this.timerPicker){
        ElMessage.warning({
          message: '待办开始时间不能为空',
          type: 'warning'
        });
        return
      };
      const timeValue = transformDateTime(this.timerPicker)
      this.addBusinessLoading = true;
      let res = await quickCreate({
        beginDate:timeValue,
        todoName:this.addBusinessInput
      });
      if(res&&res.code==200){
        ElMessage.success({
          message: '创建成功！',
          type: 'success'
        });
      };
      this.addBusinessBtn = false;
      this.addBusinessLoading= false;
      // 获取当日的待办列表
      this.getTodoList();
      // 更新月视图
      this.$refs.calendar.getMonthView();
    },
    // 获取当日待办
    async getTodoList(){
      this.loading = true;
      let res = await today({
        specifyDay:this.specifyDay
      });
      if(res&&res.code==200){
        this.leftRemindAllList = res.data.todoList;
        if(this.leftRemindAllList.length){
          if(this.checkedIsShow){
            this.leftRemindList = this.leftRemindAllList
          }else{
            this.leftRemindList = this.leftRemindAllList.filter(item=>{
              return item.isFinish === 0
            })
          }
        }else{
          this.leftRemindList = []
        }
      };
      this.loading = false
    },
    // 完成待办/取消完成待办
    async checkBox(idx){
      const remindItem = this.leftRemindList[idx];
      this.loading = true;
      let res = await check({
        isFinish:remindItem.isFinish?0:1,
        todoId:remindItem.todoId
      })
      if(res&&res.code==200){
        this.getTodoList()
        // 更新月视图
        this.$refs.calendar.getMonthView();
      } ;
      this.loading = false;  
    },
    // 用户点击日历选择日期
    changeDate(val){
      this.specifyDay = transformDateTime(val.newV);
      this.getTodoList()
    },
    // 新建案件
    save4(){
      this.$refs['customForm'].validate(async (valid) => {
        if (valid) {
          let res = await addCustomer(this.customForm)
          if (res.code == 200) {
            ElMessage.success("新建成功")
            this.isShow = false
          }
        }
      })
    },
    // 我的菜单新建待办
    async enter(id){
      console.log('我的菜单：',id)
      if(id===1){
        this.oppIsShowCase = true;
        this.isShowRadioGroup = true
      };
      if(id===2){
        this.oppIsShowProject = true;
        this.isShowRadioGroupProject = true
      };
      if(id===3){
        this.toDoListIsShow = true;
        let res = await this.$store.dispatch('getDataDictionary',10001);
        this.selectVal = res;
      };
      if(id===4){
        this.isShow4 = true;
        this.customForm = {
          customerName: '',
          customerType: 1,
          birthday: '',
          customerRemark: '',
          tagList: []
        }
      };
    },
  handleCloseDialog4(){
    this.isShow4 = false;
  },
    // 编辑待办
    addRemindChange(){
      this.toDoFormChange.remind.push({
        key:Date.now(),
        value:''
      })
    },
    // 删除提醒
    deleteRemindChange(idx){
      if(idx===0){return}
      this.toDoFormChange.remind.splice(idx,1)
    },
    // 撤销完成
    toDoListRevokeToDoFormChange(){
      this.toDoFormChange.isFinish = 0;
      this.toDoListSubmitToDoFormChangeApiFn();
      // 刷新左边待办列表
      this.getTodoList();
      // 刷新月视图数据
      this.$refs.calendar.getMonthView();
      this.toDoListCloseDialogChange()
    },
    // 重置表單
    toDoListDialogAfterLeaveChange(){
     this.toDoFormChange = {
        title:'',
        details:'',
        startTime:'',
        endTime:'',
        remind:[],
        isFinish:'',
        relateType:'',
        todoId:'',
        relation:'',
        relationContent:''
      }
    },
    // 编辑待办 删除
    async toDoListDeletToDoFormChange(){
      let res = await remove({
        todoId:this.toDoFormChange.todoId
      });
      if(res&&res.code == 200){
        ElMessage.success({
          message: '删除成功！',
          type: 'success'
        });
      }
      // 刷新左边待办列表
      this.getTodoList();
      // 刷新月视图数据
      this.$refs.calendar.getMonthView();
      this.toDoListCloseDialogChange()
    },
    // 编辑待办 已完成
    toDoListFinishedToDoFormChange(){
      this.toDoFormChange.isFinish = 1;
      this.toDoListSubmitToDoFormChangeApiFn();
      // 刷新左边待办列表
      this.getTodoList();
      // 刷新月视图数据
      this.$refs.calendar.getMonthView();
      this.toDoListCloseDialogChange()
    },
    // 编辑待办 保存
    toDoListSubmitToDoFormChange(){
      this.toDoListSubmitToDoFormChangeApiFn();
      this.getTodoList();
      this.toDoListCloseDialogChange()
    },
    // 编辑保存提交
    async toDoListSubmitToDoFormChangeApiFn(){
      let reminderList;
      if(this.toDoFormChange.remind.length){
        reminderList = []
        this.toDoFormChange.remind.forEach(item=>{
          if(item.value === 1&&item.selfValue){
            reminderList.push({
              remindType:1,
              remindTime:transformDateTime(item.selfValue)
            })
          }else{
            reminderList.push({
              remindType:item.value
            })
          }
        });
      }else{
        reminderList = [];
      };
      if(reminderList.length&&reminderList.length==1){
        if(!reminderList[0].remindType){
          reminderList = []
        }
      }else{
        reminderList = []
      };
      let res = await update({
        beginDate:this.toDoFormChange.startTime,
        endDate:this.toDoFormChange.endTime?this.toDoFormChange.endTime:'',
        isFinish:this.toDoFormChange.isFinish,
        relateType:1,
        reminderList,
        todoId:this.toDoFormChange.todoId,
        todoName:this.toDoFormChange.title,
        todoRemark:this.toDoFormChange.details
      });
      console.log('编辑提交:',res)
    },
    toDoListCloseDialogChange(){
      this.toDoListIsShowChange = false
    },
    toDoListCloseDialog(){
      this.toDoListIsShow = false
    },
    toDoListSubmitToDoForm(){
      this.$refs['ToDoForm'].validate(async (val,obj)=>{
        if(val){
          let reminderList
          if(this.toDoForm.remind.length){
            reminderList = [];
            this.toDoForm.remind.forEach(item=>{
              if(item.value === 1&&item.selfValue){
                reminderList.push({
                  remindType:1,
                  remindTime:transformDateTime(item.selfValue)
                })
              }else{
                reminderList.push({
                  remindType:item.value
                })
              }
            });
          }else{
            reminderList = ''
          };
          if(reminderList.length&&reminderList.length==1){
            if(!reminderList[0].remindType){
              reminderList = []
            }
          }else{
            reminderList = []
          };
          let res = await create({
            beginDate:transformDateTime(this.toDoForm.startTime),
            endDate:this.toDoForm.endTime?transformDateTime(this.toDoForm.endTime):'',
            relateType:1,
            reminderList,
            todoName:this.toDoForm.title,
            todoRemark:this.toDoForm.details,
          });
          if(res&&res.code == 200){
            ElMessage.success({
              message: '创建成功！',
              type: 'success'
            });
            // 刷新左边待办列表
            this.getTodoList();
            // 刷新月视图数据
            this.$refs.calendar.getMonthView();
            this.toDoListCancelToDoForm();
          }
        }
      })
    },
    // 新建待办
    toDoListDialogAfterLeave(){
      // 重置表單
      this.toDoForm = {
        title:'',
        details:'',
        startTime:'',
        endTime:'',
        remind:[{
          key:Date.now(),
          value:'',
          selfValue:''
        }],
        relation:'',
        relationContent:''
      }
    },
    toDoListCancelToDoForm(){
      this.toDoListIsShow = false;
    },
    addRemind(){
      this.toDoForm.remind.push({
        key:Date.now(),
        value:''
      })
    },
    deleteRemind(idx){
      if(idx===0){return}
      this.toDoForm.remind.splice(idx,1)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.homeContent{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:720px;
  .rightBox,.leftBox{
    width:230px;
    height:100%;
    box-sizing: border-box;
    padding:0 20px;
  }
  .leftBox{
    .leftTop{
      display: flex;
      align-items: center;
      border-bottom:1px solid #ddd;
      padding:20px 0;
      justify-content: space-between;
      .title{
        font-size: 18px;
        color:#444;
      }
      .date{
        font-size: 14px;
        color:#777;
      }
    }
    .leftBody{
      max-height:590px;
      .itemBox{
        border-bottom:1px solid #ddd;
        padding:20px 0 10px 0;
        &:last-child{
          border-bottom:none
        }
        .itemTopBox{
          margin-bottom:20px;
          .itemTop{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
              font-size: 14px;
              color:#666;
              width:160px;
              @include ellipsis;
              &:hover{
                cursor:pointer;
                color:$main-color;
              }
            }
            .checkBox{
              width:16px;
              height:16px;
              border:1px solid #ddd;
              &:hover{
                cursor:pointer;
              }
              .iconduihao{
                color:$main-color;
              }
            }
          }
          .time{
            margin-top: 10px;
            .timeText{
              font-size: 14px;
              color:#666;
            }
          }
        }
      }
    }
    .leftFooter{
      margin-top:20px;
      .addBusiness{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .addText{
          font-size: 16px;
          color:#666;
          span{
            color:$main-color;
            margin-left:6px;
          }
        }
        .iconfont{
          font-size: 30px;
          color:$main-color;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
  .centerBox{
    width:740px;
    height:100%;
    box-sizing: border-box;
    padding:0 20px;
    border-right:1px solid #eee;
    border-left:1px solid #eee;
  }
  .rightBox{
    .title{
      font-size: 18px;
      color: #444;
      padding:20px 0;
    }
    .menuLis{
      width:100%;
      display: flex;
      flex-wrap: wrap;
      height:210px;
      .menuItem{
        width:50%;
        height:50%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color:#666;
        &:hover{
          cursor: pointer;
          .menuText{
            color:$main-color
          }
        }
        &:first-child{
          border-right:1px solid #eee;
          border-bottom:1px solid #eee;
        }
        &:nth-child(2){
          border-bottom:1px solid #eee;
        }
        &:nth-child(3){
          border-right:1px solid #eee;
        }
        .iconfont{
          font-size: 30px;
          color:$main-color;
          margin-bottom:10px;
        }
        .iconanjiantiaocha{
          font-size: 32px;
        }
      }
    }
    .problem{
      width:138px;
      margin-left: 40px;
      margin-top:120px;
      display: flex;
      color:$main-color;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 18px;
      &:hover{
        cursor: pointer;
      }
      .iconfont{
        font-size: 54px;
      }
      .problemText{
        font-weight: 500;
      }
    }
    .video{
      width:138px;
      margin-left: 40px;
      margin-top:211px;
      display: flex;
      color:#51C3F5;
      align-items: flex-end;
      justify-content: flex-end;
      font-size: 14px;
      &:hover{
        cursor: pointer;
      }
      .iconfont{
        font-size: 25px;
        margin-left:8px
      }
    }
  }
}
#toDoForm{
  padding:0 20px;
  height:600px;
  overflow-y: scroll;
  display: flex;
    flex-direction: column;
    justify-content: space-around;
  .btngorw{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .el-form-item{
      width:220px;
    }
  }
  .addBox{
    .addTitle{
      margin-bottom:20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        font-size: 14px;
        color: #606266;
        height:40px;
        line-height: 40px;
      }
    }
    .list{
      width:100%;
      .listItem{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:25px;
        .el-icon-delete{
          font-size: 24px;
          color:#999;
          &:hover{
            cursor: pointer;
            color: $main-color;
          }
        }
      }
    }
  }
  .relation{
    width:100%;
    display: flex;
    align-items: center;
    .relationInput{
      width:75%
    }
  }
}
.custom_form {
    padding: 20px 30px;

    .form_box {
      display: flex;

      .form_item_type {
        flex: 0 0 260px;
      }

      .form_item_name {
        flex: 1;

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }

    .btn_wrap {
      display: flex;
      justify-content: center;
    }
  }
.radioGroup{
  padding-bottom:30px;
  .el-radio-group{
    display: flex;
    align-items: center;
    width: 480px;
    padding-left: 45px;
    flex-wrap: wrap;
    margin: auto;
    .el-radio{
      width:33%;
      margin-right: 0;
      margin-top:25px;
    }
  }
}
.formBaseInfo{
  .lawyerBox{
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    width:100%;
    box-sizing: border-box;
    padding:30px 30px 0 30px;
    // overflow-y: scroll;
    // height: 155px;
    .itemLawyer{
      display: flex;
      align-items:center;
      margin-bottom:20px;
      .titleItem{
        font-size: 14px;
        color: #666;
      }
      .inputItem{
        width:420px;
        margin-left: 10px;
      }
    }
  }
  .belongBox{
    
    padding-top:30px;
    width:486px;
    padding-left:30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .caseBelong{
      display: flex;
      align-items: center;
      margin-right: 40px;
      &:last-child{
        margin: 0;
      }
      .text{
        font-size: 14px;
        color:#666;
        margin-right: 10px;
      }
    }
  }
  .bottomBox{
    padding:30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
