<template>
  <div class="calendar">
    <el-calendar v-model="selfData">
      <template #dateCell="{ data }">
        <div :class="data.isSelected ? 'is-selected' : ''">
          <div class="dateTitle">
            {{ getDay(data.day) }}
            <span class="todyText" v-if="data.day === currentDte()">今天</span>
          </div>
          <!-- 渲染小格子 -->
          <div class="mainCon" v-if='monthData.size'>
            <div class="item">
              <div class="block" 
              v-for="item in (getRemindList(data.day))" 
              :key="item.todoId"
              :class="item.isFinish?'actived':''"></div>
            </div>
          </div>

        </div>
      </template>
    </el-calendar>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {monthView} from '@/api/index.js';
export default defineComponent({
  emits:['changeDate'],
  data(){
    return {
      monthDataRes:[],
      monthData:new Map(),
      selfData:null,
    }
  },
  created(){
    // 数据获取
    this.getMonthView();
  },
  mounted(){
    this.calendarInit(this.getCurrentDate)
  },
  watch:{
    selfData(newV,oldV){
      let selfDataVal = this.getCurrentDate(newV);
      this.changeTitle(selfDataVal);
      this.$store.commit('changeDate',selfDataVal);
      this.$emit('changeDate',{
        selfDataVal,
        newV
      });
    }
  },
  methods: {
    changeTitle(_current){
      let centerSpan = document.getElementsByClassName("el-calendar__header")[0]
        .childNodes[1].childNodes[0].childNodes[2].childNodes;
      centerSpan[2].innerHTML = _current;
    },
    // 处理日历的天显示
    getDay(date){
      return date.substr(-2)[0] === "0" ? date.substr(-1) : date.substr(-2);
    },
    // 获取当前日期
    currentDte(){
      const date = new Date();
      const year = date.getFullYear();
      let mon = date.getMonth() + 1;
      let day = date.getDate();
      mon = mon < 10 ? `0${mon}` : mon;
      day = day < 10 ? `0${day}` : day;
      return `${year}-${mon}-${day}`;
    },
    calendarInit(getCurrentDate){
      let targetBox = document.getElementsByClassName("el-calendar__header")[0]
      .childNodes[1].childNodes[0].childNodes;
      let leftSpan = targetBox[1].childNodes;
      let centerSpan = targetBox[2].childNodes;
      let rightSpan = targetBox[3].childNodes;
      rightSpan[2].innerHTML = "";
      rightSpan[2].className = 'iconfont iconrtArrow'
      leftSpan[2].innerHTML = "";
      leftSpan[2].className = 'iconfont iconlfArrow'
      centerSpan[2].innerHTML = getCurrentDate();
    },
    getCurrentDate(paramsDate){
      const date = paramsDate ? new Date(paramsDate) : new Date();
      const year = date.getFullYear();
      const mon = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${mon}月${day}日`;
    },
    async getMonthView(){
      let res = await monthView();
      if(res&&res.code==200){
        this.monthDataRes = res.data.dayGroupList;
        // 数据处理
        if(this.monthDataRes.length){
          this.monthDataRes.forEach(item=>{
            if(item.listDate.length>8){
              const arr = item.listDate.slice(0,9);
              this.monthData.set( item.date.substring(0,10), arr )
            }else{
              this.monthData.set( item.date.substring(0,10), item.listDate )
            }
          });
        };
      };
    },
    getRemindList(day){
      if(this.monthData.has(day)){
        return this.monthData.get(day)
      };
      return []
    }
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/style/comman.scss";

.el-calendar-table {
  .item {
    display: flex;
    flex-wrap: wrap;
    .block {
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid $main-color;
      margin-right: 15px;
      margin-bottom: 6px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .actived {
      background: $main-color;
    }
  }
}
.calendar {
  width: 700px;
  margin: auto;
  .dateTitle {
    margin-bottom: 6px;
  }
  ::v-deep {
    .el-calendar__body {
      padding: 0;
      .el-calendar-table {
        thead {
          background: #f6f7fa;
          th {
            border-right: 1px solid #ebeef5;
            &:first-child {
              border-left: 1px solid #ebeef5;
            }
          }
        }
      }
    }

    .el-calendar__header {
      justify-content: center;
    }
    .el-calendar__title {
      display: none;
    }
    .el-calendar-table td {
      overflow: hidden;
    }
    .el-calendar-table td.is-today {
      background: #ff8600;
      color: #fff;
      .dateTitle {
        font-size: 12px;
        .todyText {
          font-size: 14px;
        }
      }
      .mainCon {
        .item {
          .actived {
            background: #e87500;
            border-color: #e87500;
          }
        }
      }
    }
    .el-calendar-table td.is-selected {
      background: #ff8600;
      .mainCon {
        .item {
          .actived {
            background: #e87500;
            border-color: #e87500;
          }
        }
      }
    }
    .el-calendar-table td.next {
      .mainCon {
        .item {
          .block {
            border-color: #fccd9a;
          }
          .actived {
            background: #fccd9a;
            border-color: #fccd9a;
          }
        }
      }
    }
    .el-calendar-table td.prev {
      .mainCon {
        .item {
          .block {
            border-color: #fccd9a;
          }
          .actived {
            background: #fccd9a;
            border-color: #fccd9a;
          }
        }
      }
    }
    .el-calendar-table .el-calendar-day:hover {
      background: rgba(255, 134, 0, 0.5);
    }
    .el-calendar-table .el-calendar-day {
      .dateTitle {
        font-size: 14px;
      }
    }
    .el-button-group > .el-button {
      border: none;
      outline: none;
    }
    .el-button-group > .el-button {
      font-size: 14px;
    }
    .el-button-group > .el-button:first-child {
      font-size: 16px;
    }
    .el-button-group > .el-button:last-child {
      font-size: 16px;
    }
    .el-calendar-table .el-calendar-day {
      height: 96px;
    }
  }
}
</style>